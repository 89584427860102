import 'react-confirm-alert/src/react-confirm-alert.css'; //
import React, { useEffect, useState } from "react"
import {Link} from 'react-router-dom'
import Select from 'react-select'
import axios from "axios";
import { confirmAlert } from 'react-confirm-alert'; // Import
import context from "../../../context";
import ReactPaginate from 'react-paginate';
import { CURRENCIES } from '../../../util/general.util';
import ExchangeRateComponent from './ExchangeRateComponent';

const ExchangeRates = ({
    role,
    showLoaderAction,
    showErrorAction
}) => {

    const [exchangeRates, setExchangeRates] = useState([]);

    const [filters, setFilters] = useState({
        currencyFilter: { value: '', label: 'Select Currency' },
        pageFilter: 1,
        limitFilter: 10
    });

    const [filterOptions, setFilterOptions] = useState({
        currencies: CURRENCIES,
    });

    const [totalPages, setTotalPages] = useState(100);

    useEffect(()=>{
        fetchExchangeRates(filters);
    },[])

    const fetchExchangeRates = (filters) => {
        showLoaderAction(true);
        const { currencyFilter, pageFilter, limitFilter } = filters;
        axios.get(`${context.SERVER_URL}/api/exchangeRates?currencyFilter=${currencyFilter?.value}&pageFilter=${pageFilter}&limitFilter=${limitFilter}`)
        .then((response)=>{
            const { exchangeRates, totalPages, currentPage} = response.data;
            setExchangeRates(exchangeRates);
            setTotalPages(totalPages);
        }).catch((error)=>{
            console.log(error);
        })
        .finally(()=>{
            showLoaderAction(false);
        })
    }

    const updateExchangeRate = ({ id, data }) => {
        showLoaderAction(true);
        axios.put(`${context.SERVER_URL}/api/exchangeRates/${id}`,{
            data:{
                ...data
            }
        })
        .then((response)=>{
            console.log(response);
            fetchExchangeRates(filters);
        }).catch((error)=>{
            console.log(error);
        })
        .finally(()=>{
            showLoaderAction(false);
        })
    }

    const deleteExchangeRate = (id) =>{

        const options = {
            title: 'Delete Exchange Rate',
            message: 'Are you sure ?',
            buttons: [
            {
                label: 'Yes',
                onClick: () => {
                    axios.delete(`${context.SERVER_URL}/api/exchangeRates/${id}`)
                    .then((response)=>{
                        const _exchangeRates = exchangeRates.filter((exchangeRate)=> exchangeRate._id != id)
                        setExchangeRates(_exchangeRates);
                    })
                    .catch((error)=>{
                        console.log(error);
                    })
                }
            },
            {
                label: 'No',
                onClick: () =>{}
            }
            ],
            closeOnEscape: true,
            closeOnClickOutside: true,
            keyCodeForClose: [8, 32],
            willUnmount: () => {},
            afterClose: () => {},
            onClickOutside: () => {},
            onKeypress: () => {},
            onKeypressEscape: () => {},
            overlayClassName: "overlay-custom-class-name"
        };

        confirmAlert(options);
    }

    return(
        <div className="w-full bg-white p-5 overflow-y-auto">
            <div className="flex w-full justify-between mb-2 items-center">
                <h1 className="text-primary-1 font-bold">ExchangeRates</h1>
                <Link
                    to = {"/exchangeRates/new"}
                >
                    <div className="bg-primary-1 rounded-md text-white px-3 py-2">New Exchange Rate</div>
                </Link>
            </div>
            <div className='border border-gray-400 mt-1 mb-10'/>

            <div className="flex flex-col md:flex-row w-full gap-4" >
                <div className="grid grid-cols-4 w-full gap-4">
                    <div className="col-span-1">
                        <label className="text-sm text-primary-1 font-semibold">Make filter</label>
                        <Select
                            className="w-full"
                            placeholder="Select Currency"
                            options={filterOptions.currencies}
                            onChange={(value)=>setFilters((prevState)=>({
                                ...prevState,
                                currencyFilter: value
                            }))}
                            value={filters.currencyFilter}
                        />
                    </div>
                    <div className="col-span-1">
                        <label className="text-sm text-primary-1 font-semibold">
                            Limit filter
                        </label>
                        <input
                            className="w-fit border border-gray-200 rounded-md p-2"
                            placeholder=""
                            type = "number"
                            onChange={({target: { value }}) =>{
                                setFilters((prevState) => ({
                                    ...prevState,
                                    limitFilter: value,
                                }))
                            }}
                            value={filters.limitFilter}
                        />
                    </div>
                </div>
                <div className="flex flex-row gap-4 items-end">
                    <button
                        className="bg-primary-1 text-white rounded-lg px-4 py-2 h-fit"
                        onClick={()=>fetchExchangeRates(filters)}
                    > Search</button>
                    <button
                        className="bg-gray-500 text-white rounded-lg px-4 py-2 h-fit"
                        onClick={()=>{
                            const resetState = {
                                currencyFilter: { value: '', label: 'Select Currency' },
                                limitFilter: 10,
                                pageFilter: 1
                            }
                            setFilters((prevState)=>({
                                ...prevState,
                                ...resetState
                            }))
                            fetchExchangeRates(resetState)
                        }}
                    > Reset</button>
                </div>
            </div>

            <div className="-mx-4 mt-10 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                    <tr>
                        <th
                            scope="col"
                            className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-primary-2 sm:pl-6"
                        >
                            Currency
                        </th>
                        <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-primary-2 lg:table-cell"
                        >
                            Deposit
                        </th>
                        <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-primary-2 lg:table-cell"
                        >
                            Widthdraw
                        </th>
                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                            <span className="sr-only">Select</span>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    {exchangeRates.map((exchangeRate, index) => (
                        <ExchangeRateComponent
                            key={exchangeRate._id}
                            exchangeRate={exchangeRate}
                            index={index}
                            role={role}
                            updateExchangeRate={updateExchangeRate}
                            deleteExchangeRate={deleteExchangeRate}
                        />
                    ))}
                    </tbody>
                </table>
            </div>
        <div className="flex flex-row w-full items-center justify-end mt-5">
            <ReactPaginate
                previousLabel={'Previous'}
                nextLabel={'Next'}
                breakLabel={'...'}
                pageCount={totalPages}
                marginPagesDisplayed={2}
                pageRangeDisplayed={3}
                onPageChange={({selected}) => {
                    let _filters = {
                        ...filters,
                        pageFilter: selected + 1
                    } 
                    setFilters(_filters);
                    fetchExchangeRates(_filters);
                }}
                containerClassName={'pagination'}
                pageClassName={'page'}
                pageLinkClassName={'page-link'}
                previousClassName={'previous'}
                nextClassName={'next'}
                activeClassName={'active'}
                disabledClassName={'disabled'}      
                />
            </div>
        </div>
    )
}
export default ExchangeRates;