import React,{useState} from "react";

import { Link, useLocation } from "react-router-dom";
import axios from "axios";
import context from "../../../context/index";
import { CURRENCIES } from "../../../util/general.util";
import Select from 'react-select';

const NewWallet = () => {

    const location = useLocation();
    const data = location?.state;
    const [currency, setCurrency] = useState(null);

    const submitCar = () => {

        axios.post(`${context.SERVER_URL}/api/wallets`,
        {
            data:
            {
                ownerType: data?.ownerType,
                owner: data?.owner,
                currency: currency?.value
            }

        }).then((response)=>{   

            console.log(response)

        }).catch((error)=>{
            console.log(error);

        })
    }

    return(
        <div className="w-full h-full p-5 overflow-y-auto">
            <h1 className="text-primary-1 font-bold">New Wallet</h1>
            <div className='border border-gray-400 mt-1'/>
            <div className='text-gray-500 mb-10'>
                <Link 
                    to = {"/wallets"}
                    className = "underline pr-2"
                >wallets</Link>
            </div>

            <div className="flex flex-row mt-5 w-full">
                <label className="text-primary-1 font-bold mr-4">Name:</label>
                <div className="rounded-lg">{ data?.name }</div>
            </div>

            <div className="flex flex-row mt-5 w-full">
                <label className="text-primary-1 font-bold mr-4">Owner Type:</label>
                <div className="rounded-lg">{ data?.ownerType }</div>
            </div>
        
            <div className="flex flex-col w-full mt-5">
                <label className="text-sm text-primary-1 font-semibold">Currency</label>
                <Select
                    className="w-full"
                    placeholder="Select Currency"
                    options={CURRENCIES}
                    onChange={(value)=> setCurrency(value)}
                    value={currency}
                />
            </div>

            <button className="py-2 px-3 bg-primary-1 text-white font-bold rounded-md mt-5" onClick={()=> submitCar()}>Submit</button>
                        
        </div>
    )

}

export default NewWallet;