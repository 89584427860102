import 'react-confirm-alert/src/react-confirm-alert.css'; //

import * as WalletPermissions from '../modules/WalletPermissions';

import React, { useEffect, useState } from "react"

import {Link} from 'react-router-dom'
import Select from 'react-select'
import axios from "axios";
import context from "../../../context";
import ReactPaginate from 'react-paginate';

import { CURRENCIES, OWNER_TYPES } from '../../../util/general.util';

const Wallets = ({
    role,
    showLoaderAction,
    showErrorAction
}) => {

    const [wallets, setWallets] = useState([]);

    const [filters, setFilters] = useState({
        currencyFilter: { value: '', label: 'Select Currency' },
        searchFilter: '',
        ownerTypeFilter: { value: '', label: 'Select Owner Type' },
        pageFilter: 1,
        limitFilter: 10
    });

    const [filterOptions, setFilterOptions] = useState({
        currencies: CURRENCIES,
        ownerTypes: OWNER_TYPES,
    });

    const [totalPages, setTotalPages] = useState(100);

    function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
    }

    useEffect(()=>{
        fetchWallets(filters);
    },[])

    const fetchWallets = (filters) => {
        showLoaderAction(true);
        const { searchFilter, ownerTypeFilter, currencyFilter, pageFilter, limitFilter } = filters;
        axios.get(`${context.SERVER_URL}/api/wallets?searchFilter=${searchFilter}&ownerTypeFilter=${ownerTypeFilter?.value}&currencyFilter=${currencyFilter?.value}&pageFilter=${pageFilter}&limitFilter=${limitFilter}`)
        .then((response)=>{
            const { wallets, totalPages, currentPage} = response.data;
            setWallets(wallets);
            setTotalPages(totalPages);
        }).catch((error)=>{
            console.log(error);
        })
        .finally(()=>{
            showLoaderAction(false);
        })
    }

    return(
        <div className="w-full bg-white p-5 overflow-y-auto">
            <div className="flex w-full justify-between mb-2 items-center">
                <h1 className="text-primary-1 font-bold">Wallets</h1>
                {/* <Link
                    to = {"/cars/new"}
                >
                    <div className="bg-primary-1 rounded-md text-white px-3 py-2">New Wallet</div>
                </Link> */}
            </div>
            <div className='border border-gray-400 mt-1 mb-10'/>

            <div className="flex flex-col md:flex-row w-full gap-4" >
                <div className="grid grid-cols-4 w-full gap-5">
                    <div className="col-span-1">
                        <label className="text-sm text-primary-1 font-semibold">Search filter</label>
                        <input
                            className="w-full rounded-lg border p-2"
                            placeholder="Search"
                            onChange={({ target: { value } }) => {
                                setFilters((prevState)=>({
                                    ...prevState,
                                    searchFilter: value
                                }))
                            }}
                            value={filters.searchFilter}
                        />
                    </div>
                    <div className="col-span-1">
                        <label className="text-sm text-primary-1 font-semibold">Owner Type filter</label>
                        <Select
                            className="w-full"
                            placeholder="Select Model"
                            options={filterOptions.ownerTypes}
                            onChange={(value)=>setFilters((prevState)=>({
                                ...prevState,
                                ownerTypeFilter: value
                            }))}
                            value={filters.ownerTypeFilter}
                        />
                    </div>
                    <div className="col-span-1">
                        <label className="text-sm text-primary-1 font-semibold">Currency filter</label>
                        <Select
                            className="w-full"
                            placeholder="Select Currency"
                            options={filterOptions.currencies}
                            onChange={(value)=>setFilters((prevState)=>({
                                ...prevState,
                                currencyFilter: value
                            }))}
                            value={filters.currencyFilter}
                        />
                    </div>
                    <div className="col-span-1 flex flex-col">
                        <label className="text-sm text-primary-1 font-semibold">
                            Limit filter
                        </label>
                        <input
                            className="w-fit border border-gray-200 rounded-md p-2"
                            placeholder=""
                            type = "number"
                            onChange={({target: { value }}) =>{
                                setFilters((prevState) => ({
                                ...prevState,
                                limitFilter: value,
                                }))
                            }}
                            value={filters.limitFilter}
                        />
                    </div>
                </div>
                <div className="flex flex-row gap-4 items-end">
                    <button
                        className="bg-primary-1 text-white rounded-lg px-4 py-2 h-fit"
                        onClick={()=>fetchWallets(filters)}
                    > Search</button>
                    <button
                        className="bg-gray-500 text-white rounded-lg px-4 py-2 h-fit"
                        onClick={()=>{
                            const resetState = {
                                currencyFilter: { value: '', label: 'Select Currency' },
                                ownerTypeFilter: { value: '', label: 'Select Owner Type' },
                                searchFilter: '',
                                limitFilter: 10,
                                pageFilter: 1
                            }
                            setFilters((prevState)=>({
                                ...prevState,
                                ...resetState
                            }))
                            fetchWallets(resetState)
                        }}
                    > Reset</button>
                </div>
            </div>

            <div className="-mx-4 mt-10 ring-1 ring-gray-300 sm:mx-0 sm:rounded-lg">
                <table className="min-w-full divide-y divide-gray-300">
                    <thead>
                        <tr>
                            <th
                                scope="col"
                                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-primary-2 sm:pl-6"
                            >
                                Name
                            </th>
                            <th
                                scope="col"
                                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-primary-2 lg:table-cell"
                            >
                                Owner Type
                            </th>
                            <th
                                scope="col"
                                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-primary-2 lg:table-cell"
                            >
                                Currency
                            </th>
                            <th
                                scope="col"
                                className="px-3 py-3.5 text-left text-sm font-semibold text-primary-2"
                            >
                                Tokens
                            </th>
                            <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                <span className="sr-only">Select</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            wallets.map((wallet, index) => {
                                return (
                                    <tr key={wallet._id}>
                                        <td
                                            className={classNames(
                                                index === 0 ? "" : "border-t border-transparent",
                                                "relative py-4 pl-4 pr-3 text-sm sm:pl-6"
                                            )}
                                        >
                                            <div className="font-medium text-primary-2">
                                                {
                                                    wallet.ownerType === "UserModel" ? `${wallet.owner?.firstName} ${wallet.owner?.lastName}` : wallet.owner?.name
                                                }
                                            </div>

                                        </td>
                                        <td
                                            className={classNames(
                                                index === 0 ? "" : "border-t border-gray-200",
                                                "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                                            )}
                                        >
                                            {wallet.ownerType}
                                        </td>
                                        <td
                                            className={classNames(
                                                index === 0 ? "" : "border-t border-gray-200",
                                                "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                                            )}
                                        >
                                            {wallet.currency}
                                        </td>
                                        <td
                                            className={classNames(
                                                index === 0 ? "" : "border-t border-gray-200",
                                                "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                                            )}
                                        >
                                            {wallet.tokens}
                                        </td>
                                        <td
                                            className="border-t border-transparent flex
                                                relative py-3.5 text-right text-sm font-medium"
                                        >
                                            {
                                                WalletPermissions.isAuthorized("view", role) &&
                                                <Link to={`/wallets/view/${wallet._id}`}>
                                                    <div className="border p-2 m-2 rounded-md">View</div>
                                                </Link>
                                            }
                                        </td>
                                    </tr>
                                );
                            })
                        }
                    </tbody>
                </table>
            </div>
            <div className="flex flex-row w-full items-center justify-end mt-5">
                <ReactPaginate
                    previousLabel={'Previous'}
                    nextLabel={'Next'}
                    breakLabel={'...'}
                    pageCount={totalPages}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={3}
                    onPageChange={({selected}) => {
                        let _filters = {
                            ...filters,
                            pageFilter: selected + 1
                        } 
                        setFilters(_filters);
                        fetchWallets(_filters);
                    }}
                    containerClassName={'pagination'}
                    pageClassName={'page'}
                    pageLinkClassName={'page-link'}
                    previousClassName={'previous'}
                    nextClassName={'next'}
                    activeClassName={'active'}
                    disabledClassName={'disabled'}      
                />
            </div>
        </div>
    )
}
export default Wallets;