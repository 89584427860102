import update from 'react-addons-update'
import actionConstants from './actionConstants'
import axios from 'axios';
import context from '../../../context/index';
import { showLoaderAction } from '../../common/modules/modal';
export const initialBookingState = {
    booking: null,
    bookings:[],
    currentPage: 1,
    totalPages: 10,
    trailerLocationCoordinates: []
};


const {
    GET_BOOKINGS_DATA,
    GET_BOOKING_DATA,
    DELETE_BOOKING,
    TRAILER_LOCATION_UPDATE,
    SOCKET_BOOKING_UPDATE,
    SOCKET_BOOKING_POINT_UPDATE
} = actionConstants;

export const getBookingsDataAction = (filters) => {
    return (dispatch, store) =>{
        dispatch(showLoaderAction(true));
        const {
            searchFilter,
            dateFilter,
            timeslotFilter,
            statusFilter,
            companyFilter,
            pathTypeFilter,
            pageFilter,
            limitFilter
        } = filters;

        let _date = "";
        if (dateFilter?.value) {
            const date = new Date(dateFilter.value);
            _date = JSON.stringify({
                month: date.getMonth(),
                date: date.getDate(),
                year: date.getFullYear(),
            });
        }

        const params = new URLSearchParams({
            searchFilter: searchFilter || "",
            dateFilter: _date,
            timeslotFilter: timeslotFilter?.value || "",
            statusFilter: statusFilter?.value || "",
            companyFilter: companyFilter?.value || "",
            pathTypeFilter: pathTypeFilter?.value || "",
            pageFilter: pageFilter || "",
            limitFilter: limitFilter || "",
        });

        axios.get(`${context.SERVER_URL}/api/bookings?${params.toString()}`)
        .then((res) => {
            const {bookings, currentPage, totalPages}  = res.data;
            dispatch({
                type: GET_BOOKINGS_DATA,
                payload:{
                    bookings,
                    currentPage,
                    totalPages
                }
            })      
        })
        .catch((err) => {
            console.error("Error fetching bookings:", err);
        })
        .finally(()=>{
            dispatch(showLoaderAction(false));
        })
    }
};
const getTripsFromPath = (path) => {
    const trips = path.map(point => point.trip).filter(trip => trip != null);
    const uniqueTrips = [...new Set(trips.map(trip => JSON.stringify(trip)))].map(trip => JSON.parse(trip));
    return uniqueTrips;
}

export const getBookingDataAction = (id) =>{
    return (dispatch, store) => {
        dispatch(showLoaderAction(true))
        axios.get(`${context.SERVER_URL}/api/bookings/${id}`)
            .then((res) => {
                const trips = Object.entries(res.data?.transitPath).filter(([key,_]) => key != null).map(([_, path]) => path[0]?.trip);
                const tripsToTrack = trips?.filter( trip => trip?.trailer?._id && trip.status === "onroute") || [];
                const trailerLocationCoordinates = tripsToTrack?.map(trip => ({
                    _id: trip.trailer._id, 
                    coordinates: trip.trailer?.location[0]?.location?.coordinates
                }));

                dispatch({
                    type: GET_BOOKING_DATA,
                    payload:{
                        booking: {
                            ...res.data,
                            trailerLocationCoordinates
                        }
                    }
                })
            }).catch((err) => {
                console.log(err)
            }).finally(()=>{
                dispatch(showLoaderAction(false))
            })
    
    }    
}

export const deleteBookingAction = (id) =>{
    return (dispatch, store) =>{
        dispatch(showLoaderAction(true));
        axios.delete(`${context.SERVER_URL}/api/bookings/${id}`)
        .then((response) => {
            const bookings = store().bookings.bookings.filter((booking) => booking._id != id);
            dispatch({
                type: DELETE_BOOKING,
                payload:{
                    bookings
                }
            });
        })        
        .catch((error) => {
          console.log(error);
        })
        .finally(()=>{
            dispatch(showLoaderAction(false))
        })
    }
}

export const trailerLocationUpdateAction = (trailer) =>{
    // get the trip to update
    return (dispatch, store) => {
        dispatch({
            type: TRAILER_LOCATION_UPDATE,
            payload:{
                _id: trailer?._id,
                coordinates: trailer?.location?.coordinates,
            }
        })
    }    
}

// sockets
export const socketBookingPointUpdateAction = (point) =>{
    return (dispatch, store) => {
        let booking = store()?.bookings?.booking;
        if (booking?._id == point.booking || booking?._id == point?.booking?._id){
            let path = booking?.path?.map((p)=>{
                if(p?._id == point._id) return point;
                return p;
            });

            const trips = Object.entries(point.booking?.transitPath || {}).filter(([key,_]) => key != null).map(([_, path]) => path[0]?.trip);
            const tripsToTrack = trips?.filter( trip => trip?.trailer?._id && trip.status === "onroute") || [];
            const trailerLocationCoordinates = tripsToTrack?.map(trip => ({
                _id: trip.trailer._id, 
                coordinates: trip.trailer?.location[0]?.location?.coordinates
            }));

            dispatch({
                type: SOCKET_BOOKING_POINT_UPDATE,
                payload:{
                    path,
                    transitPath: point.booking?.transitPath || {},
                    nextPoint: point.booking?.nextPoint || {},
                    trailerLocationCoordinates
                }
            })
        }
    }    
}

export const socketBookingUpdateAction = (booking) =>{
    return (dispatch, store) => {

        const trips = Object.entries(booking?.transitPath || {}).filter(([key,_]) => key != null).map(([_, path]) => path[0]?.trip);
        const tripsToTrack = trips?.filter( trip => trip?.trailer?._id && trip.status === "onroute") || [];
        const trailerLocationCoordinates = tripsToTrack?.map(trip => ({
            _id: trip.trailer._id, 
            coordinates: trip.trailer?.location[0]?.location?.coordinates
        }));

        dispatch({
            type: SOCKET_BOOKING_UPDATE,
            payload:{
                booking,
                trailerLocationCoordinates
            }
        })
    }    
}

function handleGetBookingsData (state, action){
    return update(state, {
        bookings:{
            bookings:{ $set: action.payload.bookings },
            currentPage:{ $set: action.payload.currentPage },
            totalPages:{ $set: action.payload.totalPages },
        }
    })
}

function handleDeleteBooking (state, action){
    return update(state, {
        bookings:{
            bookings:{ $set: action.payload.bookings },
        }
    })
}

function handleGetBookingData (state, action){
    return update(state, {
        bookings:{
            booking:{ $set: action.payload.booking },
        }
    })
}

function handleTrailerLocationUpdate (state, action){
    const { _id, coordinates } = action.payload;
    const _trailerLocationCoordinates = state.bookings.booking.trailerLocationCoordinates;
    const index = _trailerLocationCoordinates.findIndex((trailer) => trailer._id == _id);
    
    if (!index) return state;

    _trailerLocationCoordinates[index].coordinates = coordinates;

    return update(state, {
        bookings:{
            booking:{
                trailerLocationCoordinates: { $set: _trailerLocationCoordinates },
            },
        }
    })
}

function handleSocketBookingUpdate (state, action){
    return update(state, {
        bookings:{
            booking:{
                status: { $set: action.payload.booking?.status },
                nextPoint: { $set: action.payload.booking?.nextPoint },
                path: { $set: action.payload.booking?.path },
                stops: { $set: action.payload.booking?.stops },
                transitPath: { $set: action.payload.booking?.transitPath },
                trailerLocationCoordinates: { $set: action.payload.trailerLocationCoordinates },
            },
        }
    })
}

function handleSocketBookingPointUpdate (state, action){
    return update(state, {
        bookings:{
            booking:{
                path: { $set: action.payload.path },
                transitPath: { $set: action.payload.transitPath },
                trailerLocationCoordinates: { $set: action.payload.trailerLocationCoordinates },
                nextPoint: { $set: action.payload.nextPoint },
            },
        }
    })
}

export const BOOKINGS_ACTION_HANDLERS = {
    GET_BOOKINGS_DATA: handleGetBookingsData,
    GET_BOOKING_DATA: handleGetBookingData,
    TRAILER_LOCATION_UPDATE: handleTrailerLocationUpdate,
    SOCKET_BOOKING_UPDATE: handleSocketBookingUpdate,
    SOCKET_BOOKING_POINT_UPDATE: handleSocketBookingPointUpdate,
    DELETE_BOOKING: handleDeleteBooking
}