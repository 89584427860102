import {connect} from 'react-redux'
import NewExchangeRate from '../components/NewExchangeRate';

const mapStateToProps = (state) =>({
    role: state.profile.role
});

const mapActionCreators = {
};

export default connect (mapStateToProps,mapActionCreators)(NewExchangeRate); 