import React,{useState} from "react";

import { Link } from "react-router-dom";
import axios from "axios";
import context from "../../../context/index"
import { CURRENCIES } from "../../../util/general.util";
import Select from 'react-select';

const NewExchangeRate = () => {

    const [exchangeRate, setExchangeRate]= useState({
        currency: { value: '', label: 'Select Currency' },
        deposit: 0,
        withdraw: 0
    });

    const submitExchangeRate = () => {

        axios.post(`${context.SERVER_URL}/api/exchangeRates`,
        {
            data:
            {
                ...exchangeRate,
                currency: exchangeRate.currency?.value
            }

        }).then((response)=>{   

            console.log(response)

        }).catch((error)=>{
            console.log(error);

        })
    }

    return(
        <div className="w-full h-full p-5 overflow-y-auto">
            <h1 className="text-primary-1 font-bold">New Exchange Rate</h1>
            <div className='border border-gray-400 mt-1'/>
            <div className='text-gray-500 mb-10'>
                <Link 
                    to = {"/exchangeRates"}
                    className = "underline pr-2"
                >exchange rates</Link>
            </div>

            <div className="mt-5">
                <label className="text-primary-1 font-bold">Currency</label>
                <Select
                    className="w-full"
                    placeholder="Select Currency"
                    options={CURRENCIES}
                    onChange={(value)=>setExchangeRate((prevState)=>({
                        ...prevState,
                        currency: value
                    }))}
                    value={exchangeRate.curreny}
                />
            </div>
            <div className="mt-5">
                <label className="text-primary-1 font-bold">Deposit Rate</label>
                <input 
                    className="w-full p-5 mt-2 rounded-lg" 
                    type="number" 
                    value={exchangeRate.deposit} 
                    onChange={(e)=> {
                        setExchangeRate((prevState)=>({
                            ...prevState,
                            deposit: e.target.value
                        }))
                    }}
                    placeholder={"Enter Deposit Rate"}
                />
            </div>
            
            <div className="mt-5">
                <label className="text-primary-1 font-bold">Withdraw Rate</label>
                <input 
                    className="w-full p-5 mt-2 rounded-lg" 
                    type="number" 
                    value={exchangeRate.withdraw} 
                    onChange={(e)=> {
                        setExchangeRate((prevState)=>({
                            ...prevState,
                            withdraw: e.target.value
                        }))
                    }}
                    placeholder={"Enter Withdraw Rate"}
                />
            </div>

            <button className="py-2 px-3 bg-primary-1 text-white font-bold rounded-md mt-5" onClick={()=> submitExchangeRate()}>Submit</button>
                        
        </div>
    )

}

export default NewExchangeRate;