import React, {useState} from "react";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { Autocomplete } from "@react-google-maps/api";
import ExpandCircleDownIcon from '@mui/icons-material/ExpandCircleDown';
import Select from 'react-select';
import BookingProductsDistribution from "./BookingProductsDistribution";
import { POINT_TYPE } from "../../../util/general.util";
import finalPropsSelectorFactory from "react-redux/es/connect/selectorFactory";


const PointComponent = ({
    points,
    setPoints,
    calculateRoute,
    onPointChanged,
    onAddStop,
    removePoint,
    index,
    point,
    products,
    onSetPointCapacity,
    productCategories,
    pathType
}) =>{

    const [expanded, setExpanded] = useState(false);
    const [showAddStop, setShowAddStop] = useState(false);

    const getCapacityQuantity = () => {
        /*if (point.disableCapacity)
        {
            return points.reduce((totalCount, point)=>{
                //console.log(point?.type?.value);
                let _pointQuantity = point.capacity.reduce((count, product)=> {
                    return (Number(count) + Number(product.quantity))
                }, 0);

                if (point?.type?.value == "location")
                {
                    return (Number(totalCount) + Number(_pointQuantity))
                }

                if (point?.type?.value == "destination")
                {
                    return (Number(totalCount) - Number(_pointQuantity))
                }
                return 0;

            },0)

        }*/

        return  point.capacity.reduce((count, product)=> {
            return (Number(count) + Number(product.quantity))
        }, 0);
    }

    const renderCapacityQuantity = () => {
        let quantity = getCapacityQuantity();
        return(
            <div className= {`p-2 ${ (quantity <= 0) ? "" : "text-gray-600 "} `}>
                {
                    `Qty: ${quantity}`
                }
            </div>
        )
    }

    return(
        <div key= {point.identifier} className="flex flex-col w-full my-3">
            <div className="flex flex-row w-full gap-2 items-start justify-center">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-7 w-full gap-4">
                    <Autocomplete
                        className="col-span-1 lg:col-span-2"
                        //ref={point.ref}
                        onPlaceChanged={() => {
                            const { geometry:{ location }, formatted_address, address_components } = point.ref.current?.getPlace();

                            // find country
                            const countryCode = address_components.find((component)=> component.types.includes("country"))?.short_name;

                            const _points = [...points];
                            _points[index] = {
                                ..._points[index],
                                address: formatted_address,
                                latitude: location.lat(),
                                longitude: location.lng(),
                                country: countryCode
                            }
                            setPoints(_points)
                            calculateRoute(_points)
                        }}
                        onLoad={(autocomplete)=>{
                            point.ref.current = autocomplete;
                        }}
                        options={{
                            types: ["address"],
                            //componentRestrictions: { country: "za" },
                        }}
                        //defaultValue="Amsterdam"
                    >
                        <input className="rounded-md w-full py-2 border px-2 truncate" placeholder={point.placeholder} type="text" defaultValue={point.address}></input>
                    </Autocomplete>
                    <div className="col-span-1">
                        <Select
                            className="w-full"
                            placeholder="Stop type"
                            options={POINT_TYPE}
                            onChange={(value)=> onPointChanged(index, "type" ,value)}
                            value={point.type}
                            // isDisabled={point.disableTypeSelect}
                            isDisabled={true}

                        />
                    </div>
                    <div className="col-span-1">
                       {renderCapacityQuantity()}
                    </div>

                </div>

                <div className="flex w-72 gap-2 justify-end">
                <button
                            className="bg-green-custom rounded-md text-white px-3 py-2"
                            onClick={ ()=> setExpanded(!expanded)}
                        >
                            Add Products
                        </button>
                    {
                        point.showCancel &&
                        <button
                            className="bg-danger-2 rounded-md text-white px-3 py-2"
                            onClick={()=>removePoint(point.identifier)}
                        >Remove Stop</button>
                    }
                    {
                        point.showAddStop &&
                        <div>
                            <button
                            // disabled = {(point.ref.current?.getPlace() != null) ? false : true }
                                className="bg-primary-1 rounded-md text-white px-3 py-2"
                                onClick={()=>setShowAddStop(true)}
                            >
                                {"Add Stop"}
                            </button>
                            {
                                showAddStop &&
                                <div className="flex flex-row w-fit absolute z-100 bg-white p-5 top-0 right-0">
                                    {
                                        pathType?.value == "direct" &&
                                        <button
                                            className="bg-primary-1 rounded-md text-white px-3 py-2"
                                            onClick={()=>{
                                                onAddStop("pick")
                                                setShowAddStop(false);
                                            }}
                                        >Pick</button>
                                    }
                                    <button
                                        className="bg-primary-1 rounded-md text-white px-3 py-2"
                                        onClick={()=>{
                                            onAddStop("drop");
                                            setShowAddStop(false);
                                        }}
                                    >Drop</button>
                                    <button
                                        className="bg-primary-1 rounded-md text-white px-3 py-2"
                                        onClick={()=>{
                                            setShowAddStop(false);
                                        }}
                                    >Cancel</button>
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>

            {
                expanded &&
                <BookingProductsDistribution
                    products={products}
                    setExpanded={setExpanded}
                    onSetPointCapacity = {onSetPointCapacity}
                    point = {point}
                    pointIndex = {index}
                    isDestination={(index == (points.length-1))}
                    productCategories = {productCategories}
                    points = {points}
                />
            }
            { !point.disableCapacity && <div className='border border-gray-400 mt-2'/> }
        </div>
    )
}

export default PointComponent;
