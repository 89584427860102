import React, { useState } from "react";
import * as ExchangeRatePermissions from '../modules/ExchangeRatePermissions';

const ExchangeRateComponent = ({
    exchangeRate,
    index,
    role,
    updateExchangeRate,
    deleteExchangeRate
}) => {

    function classNames(...classes) {
        return classes.filter(Boolean).join(" ");
    }

    const [rate, setRate] = useState({
        currency: exchangeRate.currency,
        deposit: exchangeRate.deposit,
        withdraw: exchangeRate.withdraw
    });

    return (
        <tr key={exchangeRate._id}>
            <td
                className={classNames(
                    index === 0 ? "" : "border-t border-transparent",
                    "relative py-4 pl-4 pr-3 text-sm sm:pl-6"
                )}
            >
                <div className="font-medium text-primary-2">
                    {rate.currency}
                </div>

            </td>
            <td
                className={classNames(
                    index === 0 ? "" : "border-t border-gray-200",
                    "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                )}
            >
                <input 
                    className="font-medium text-primary-2"
                    type="number"
                    onChange={(e)=>{
                        setRate((prevState)=>({
                            ...prevState,
                            deposit: e.target.value
                        }))
                    }}
                    disabled={!ExchangeRatePermissions.isAuthorized("edit", role)}
                    value ={rate.deposit}
                />
            </td>

            <td
                className={classNames(
                    index === 0 ? "" : "border-t border-gray-200",
                    "hidden px-3 py-3.5 text-sm text-gray-500 lg:table-cell"
                )}
            >
                <input 
                    className="font-medium text-primary-2"
                    type="number"
                    disabled={!ExchangeRatePermissions.isAuthorized("edit", role)}
                    onChange={(e)=>{
                        setRate((prevState)=>({
                            ...prevState,
                            withdraw: e.target.value
                        }))
                    }}
                    value ={rate.withdraw}
                />
            </td>
            <td
                className="border-t border-transparent flex
                    relative py-3.5 text-right text-sm font-medium"

            >
                {
                    ExchangeRatePermissions.isAuthorized("edit", role) &&
                    <button className="border border-red-400 text-red-400 p-2 m-2 rounded-md" onClick = { ()=>updateExchangeRate({ id: exchangeRate._id, data: rate})}> Update </button>
                }
                {
                    ExchangeRatePermissions.isAuthorized("delete", role) &&
                    <button className="border border-red-400 text-red-400 p-2 m-2 rounded-md" onClick = { ()=>deleteExchangeRate(exchangeRate._id)}> Delete </button>
                }
            </td>
        </tr>
    );
}

export default ExchangeRateComponent;