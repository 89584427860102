import {connect} from 'react-redux'
import NewPayment from '../components/NewPayment';

const mapStateToProps = (state) =>({
    role: state.profile.role,
    userId: state.profile.id
});

const mapActionCreators = {
};

export default connect (mapStateToProps,mapActionCreators)(NewPayment); 