import * as BookingPermissions from '../modules/BookingPermissions'
import { vehicleIcon, MapStyles } from "../../../util/map.util";
import { Link, useParams, useNavigate } from 'react-router-dom'
import React, { useEffect, useState, useRef } from 'react'
import context from '../../../context'
import * as TrailerLocationUpdateReceiver from '../../../sockets/receivers/TrailerLocationUpdateReceiver';
import * as BookingUpdateReceiver from '../../../sockets/receivers/BookingUpdateReceiver';
import * as PointUpdateReceiver from '../../../sockets/receivers/PointUpdateReceiver';
import { COLOR_CODES } from '../../../util/general.util';
import {
    GoogleMap,
    useJsApiLoader,
    Marker,
    DirectionsRenderer,
    MarkerClusterer,
    Polyline
} from "@react-google-maps/api";
const libraries = ["places"];

const ViewBooking = ({
    role,
    getBookingDataAction,
    booking
}) => {
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: context.GOOGLE_API_KEY,
        libraries
    })

    const { id } = useParams();
    const [directions, setDirections] = useState(null);
    const [nextPointDirections, setNextPointDirections] = useState([]);
    const [routeArcs, setRouteArcs] = useState([]);
    const [trips, setTrips] = useState([]);
    const mapRef = useRef();
    const navigate = useNavigate();
    const strokeColors = ["#FF0000", "#00FF00", "#0000FF", "#FFFF00", "#FF00FF", "#00FFFF", "#000000", "#FFFFFF"];

    const calculateRoute = (points) => {
        let pointsLatLng = points.map((point)=>{
            return {
                lat: point.location.coordinates[1],
                lng: point.location.coordinates[0]
            }
        });

        const origin = pointsLatLng.shift();
        const destination = pointsLatLng.pop();
        let waypoints = [];
        pointsLatLng.forEach((point) => {
            if(point.lat != null)
            {
                waypoints.push({
                    location: point,
                    stopover: true
                })
            }
        });

        if (origin.lat != null && destination.lat != null)
        {
            const directionsService = new window.google.maps.DirectionsService();
            directionsService.route(
                {
                    origin: origin,
                    destination: destination,
                    waypoints,
                    travelMode: window.google.maps.TravelMode.DRIVING
                },
                (result, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        setDirections(result);
                    } else {
                    console.error(`error fetching directions ${result}`);
                    }
                }
            );
        }

    }

    const BookingPoint = ({
        point,
        index
    }) =>{
        useEffect(()=>{
            if (point?._id) PointUpdateReceiver.register(point._id);
            return () => {
                if (point?._id) PointUpdateReceiver.unregister(point._id);
            }
        },[])

        return( 
            <tr key={`${point._id}-${index}`} className='px-4'>
                <td className="whitespace-nowrap px-4 text-sm font-medium text-gray-900 sm:pl-0">
                    {point.address}
                </td>
                <td className="whitespace-nowrap px-4 text-sm font-medium text-gray-900 sm:pl-0">
                    <span className={`px-2 py-1 rounded-full text-xs font-medium ${COLOR_CODES?.point?.status[point.status]}`}>
                        {point.status.toUpperCase()}
                    </span>
                </td>
                <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell">
                    {point.country}
                </td>
                <td className={` whitespace-nowrap px-3 py-4 text-sm text-gray-500 sm:table-cell flex justify-around`}>
                    <span className={` py-1 px-4 rounded-2xl text-white  ${point.pointType === 'location' ? ' bg-link-1' : ' bg-warning-1'  } `}>
                        {point.pointType === 'location' ? 'Pick Up' : 'Drop Off' }
                    </span>
                </td>
                <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                    {
                        point.capacity?.map((capacity, index) => (
                            <div key={index} className="flex flex-row gap-2">
                                { `${capacity.description} ${capacity.quantity} ${capacity.packaging}`}
                            </div>
                        ))
                    }
                </td> 
                <td className="hidden whitespace-nowrap px-3 py-4 text-sm text-gray-500 lg:table-cell">
                    {point.confirmationCode}
                </td>
            </tr>
        )

    }

    const getTripsFromPath = (transitPath) => {
        const trips = Object.entries(transitPath).filter(([tripId, _]) => tripId != 'unassigned').map(([_, point]) => point[0].trip);
        return trips;
    }

    const tripStatuses = () => {
        return trips.map(trip => trip.status);
    }

    const tripTrailers = () => {
        return trips.map(trip => trip?.trailer?._id);
    }

    useEffect(() => {
        getBookingDataAction(id);
    }, []);

    useEffect(() => {
        if (booking?.transitPath){
            setTrips(getTripsFromPath(booking.transitPath));
        }
    },[JSON.stringify(booking?.transitPath)])

    useEffect(() => {
        if (booking?.transitPath && isLoaded && tripStatuses()?.includes("onroute") && booking?.status !== "complete"){
            let points = Object.entries(booking?.transitPath).map(([_, points]) => points).flatMap((point) => point);
            calculateRoute(points);
        }
    },[JSON.stringify(booking?.transitPath), isLoaded, booking?.status, JSON.stringify(tripStatuses())])

    useEffect(() => {
        setTimeout(() => {
            const tripToTrack = trips.find( trip => trip?.trailer?._id && trip.status === "onroute")
            trips.forEach(trip => {
                if (tripToTrack && tripToTrack._id == trip._id){
                    TrailerLocationUpdateReceiver.register(trip?.trailer?._id);
                }
                else{
                    TrailerLocationUpdateReceiver.unregister(trip?.trailer?._id);
                }
            });    
        }, 100);

        return () => {
            trips.forEach(trip => {
                TrailerLocationUpdateReceiver.unregister(trip?.trailer?._id);
            });
        }
    },[JSON.stringify(tripTrailers()), JSON.stringify(tripStatuses())])

    useEffect(()=>{
        setTimeout(()=>{
            if(booking) BookingUpdateReceiver.register(booking?._id);
        }, 100)
        return ()=> {
            if(booking) BookingUpdateReceiver.unregister(booking?._id);
        }
    },[booking?._id])

    const bookingStatusDisplay = () =>{
        if (tripStatuses()?.includes("onroute") && booking?.status !== "complete")
            return "driver on route";
        return booking?.status
    }

    const renderDriver = (trip) => {
        return (
            <>
                <div className='col-span-1 flex flex-row'>
                    <label className='text-primary-2 font-bold mr-2'>Driver :</label>
                    <div className='text-gray-500 font-bold'>{ ["pending", "scheduled"].includes(booking?.status) ? "unassigned" : trip?.driver?.firstName + " " + trip?.driver?.lastName} </div>
                </div>
                <div className='col-span-1 flex flex-row'>
                    <label className='text-primary-2 font-bold mr-2'>Vehicle Reg:</label>
                    <div className='text-gray-500 font-bold'>{["pending", "scheduled"].includes(booking?.status) ? "unassigned" : trip?.vehicle?.registrationNumber }</div>
                </div>
                <div className='col-span-1 flex flex-row'>
                    <label className='text-primary-2 font-bold mr-2'>Vehicle Color:</label>
                    <div className='text-gray-500 font-bold'>{["pending", "scheduled"].includes(booking?.status) ? "unassigned" : trip?.vehicle?.color }</div>
                </div>
                <div className='col-span-1 flex flex-row'>
                    <label className='text-primary-2 font-bold mr-2'>Trailer Reg:</label>
                    <div className='text-gray-500 font-bold'>{["pending", "scheduled"].includes(booking?.status) ? "unassigned" : trip?.trailer?.registrationNumber }</div>
                </div>
                <div className='col-span-1 flex flex-row'>
                    <label className='text-primary-2 font-bold mr-2'>Trailer Color:</label>
                    <div className='text-gray-500 font-bold'>{["pending", "scheduled"].includes(booking?.status) ? "unassigned" : trip?.trailer?.color }</div>
                </div>
            </>
        )
    }

    const getDirections = (origin, destination) => {
        const directionsService = new window.google.maps.DirectionsService();
        return new Promise((resolve, reject) => {
            directionsService.route(
                {
                    origin,
                    destination,
                    travelMode: window.google.maps.TravelMode.DRIVING,
                },
                (result, status) => {
                    if (status === window.google.maps.DirectionsStatus.OK) {
                        resolve(result); // Resolve the promise with the result
                    } else {
                        reject(`Error fetching directions: ${status}`); // Reject the promise with an error
                    }
                }
            );
        });
    };

    const getNextPointDirections = async () => {
        let onrouteTrips = trips.filter( trip => trip?.trailer?._id && trip.status === "onroute");
        const _nextPointDirections = [];
        if (isLoaded && onrouteTrips.length > 0 && booking?.trailerLocationCoordinates.length > 0){
            for(let j = 0; j < onrouteTrips.length; j++ ){
                const trip = onrouteTrips[j];
                const tripNexPoint = trip?.nextPoint;
                const bookingNextPoint = booking?.nextPoint && booking?.nextPoint[trip._id];
                if (tripNexPoint?._id == bookingNextPoint?._id){
                    const _trailerLocation = booking?.trailerLocationCoordinates.find( trailerLocation => trailerLocation._id == trip?.trailer?._id);
                    let _directions = await getDirections({
                        lng: _trailerLocation.coordinates[0],
                        lat: _trailerLocation.coordinates[1] 
                    },
                    {
                        lng: tripNexPoint?.location?.coordinates[0],
                        lat: tripNexPoint?.location?.coordinates[1]
                    });
                    _nextPointDirections.push(_directions);
                }
            }
            setNextPointDirections(_nextPointDirections);
            renderArcs();
        }
    };
    
    useEffect(() => {
        getNextPointDirections();
    },[ isLoaded , JSON.stringify(trips), JSON.stringify(booking?.transitPath), JSON.stringify(tripStatuses()), JSON.stringify(booking?.trailerLocationCoordinates)])

    useEffect(() => {
        if (booking?.path?.length && isLoaded && mapRef.current) {
            const bounds = new window.google.maps.LatLngBounds();
            booking?.path.forEach((point) => {
                bounds.extend({ 
                    lat: point.location.coordinates[1], 
                    lng: point.location.coordinates[0] 
                });
            });
            mapRef.current.fitBounds(bounds);
        }
    }, [booking.path, isLoaded, mapRef.current]);

    if (booking == null || !isLoaded) {
        return (
            <div className="w-full h-full p-5">
                <h1 className="text-primary-1 font-bold">View Booking</h1>
                <div className="h-screen flex justify-center items-center w-full">
                    <div role="status">
                        <svg
                            aria-hidden="true"
                            className="w-28 h-28 mr-2 text-gray-200 animate-spin dark:text-primary-1 fill-white"
                            viewBox="0 0 100 101"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor"
                            />
                            <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill"
                            />
                        </svg>
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            </div>
        )
    }

    const renderChatButtons = (booking) => {
        return trips.filter(trip => trip.status === "onroute").map(trip =>(
            <button
                className='border border-gray-600 rounded-md text-gray-600 bg-white hover:text-white hover:bg-gray-600 w-fit p-2'
                onClick={()=> navigate(`/chats/${booking?._id}`, { 
                    state: {
                        trip : trip._id
                    } 
                })}
            >
                {`Chat-${trip?.driver?.firstName ? trip?.driver?.firstName : "unassigned"}`}
            </button>
        ))
    }

    const renderSupportButtons = (booking) => {
        let supportButtons = [];
        if (trips.length > 0){
            supportButtons = trips.map( trip =>(
                <button
                    className='border border-gray-600 rounded-md text-gray-600 bg-white hover:text-white hover:bg-gray-600 w-fit p-2'
                    onClick={()=> navigate('/supports/new', { 
                        state: {
                            booking: booking?._id,
                            trip : trip?._id
                        } 
                    })}
                >
                    { `Support Ticket - ${ trip?.driver?.firstName ? trip?.driver?.firstName : "unassigned" }` }
                </button>
            ))
        }

        supportButtons.push(
            <button
                className='border border-gray-600 rounded-md text-gray-600 bg-white hover:text-white hover:bg-gray-600 w-fit p-2'
                onClick={()=> navigate('/supports/new', { 
                    state: {
                        booking: booking?._id,
                        trip : null
                    } 
                })}
            >
                Booking Support Ticket
            </button>
        )

        return supportButtons;
    }

    const generateBezierCurve = (start, end, numPoints = 50) => {
        const curveFactor = 0.5; // Adjust for more/less curvature
        const control = {
            lat: (start.lat + end.lat) / 2 + curveFactor * Math.abs(start.lng - end.lng),
            lng: (start.lng + end.lng) / 2 + curveFactor * Math.abs(start.lat - end.lat),
        };
    
        const points = [];
        for (let t = 0; t <= 1; t += 1 / numPoints) {
            const lat = Math.pow(1 - t, 2) * start.lat + 2 * (1 - t) * t * control.lat + Math.pow(t, 2) * end.lat;
            const lng = Math.pow(1 - t, 2) * start.lng + 2 * (1 - t) * t * control.lng + Math.pow(t, 2) * end.lng;
            points.push({ lat, lng });
        }
        return points;
    };

    const renderArcs = () => {
        const route = [];
        // check for next trip point
        Object.entries(booking.transitPath).forEach(([tripId, points], tripIndex) => {
            for ( let index = 0; index < points.length ; index++ ){
                if (index == 0) continue;
                let point = points[index];
                if (['pending', 'scheduled', 'loading', 'offloading', 'arrived'].includes(point.status)){
                    let trip = trips.find( trip => trip._id == tripId);
                    let tripNextPoint = trip?.nextPoint;
                    let bookingNextPoint = booking?.nextPoint[tripId];
                    let path = null;
                    if(trip?.status == "onroute" && tripNextPoint?._id == point._id && bookingNextPoint?._id == point._id){
                        continue;
                    }
                    else if (trip?.status == "onroute" && tripNextPoint?._id != point._id && bookingNextPoint?._id == point._id){
                        path = generateBezierCurve({ 
                            lat: points[index - 1].location.coordinates[1],
                            lng: points[index - 1].location.coordinates[0] 
                        },
                        { 
                            lat: point.location.coordinates[1],
                            lng: point.location.coordinates[0] 
                        }); 
                    } 
                    else
                    {
                        path = [
                            { lat: points[index - 1].location.coordinates[1], lng: points[index - 1].location.coordinates[0] },
                            { lat: point.location.coordinates[1], lng: point.location.coordinates[0] },
                        ]
                    }
     
                    route.push({
                        path,
                        options: {
                            // strokeColor: "#0000FF",
                            strokeOpacity: 0,
                            // strokeWeight: 2,
                            icons: [{
                                icon:{
                                    //path: "M 0,-1 0,1",
                                    path: window.google && window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
                                    strokeOpacity: 1,
                                    scale: 1.5,
                                    strokeColor: strokeColors[tripIndex]//'rgb(16,105,38)'
                                },
                                offset: '0',
                                repeat: '20px'
                            }],
                        }
                    })
                }
                else if (point.status == "complete"){
                    // draw solid polyline
                    route.push({
                        path: [
                            { lat: points[index - 1].location.coordinates[1], lng: points[index - 1].location.coordinates[0] },
                            { lat: point.location.coordinates[1], lng: point.location.coordinates[0] },
                        ],
                        options: {
                            strokeColor: "#FF00FF",
                            strokeOpacity: 1.0,
                            strokeWeight: 2,
                        }
                    });
                }
            }

        })
        setRouteArcs(route || []);
    }

    return (
        <div className='w-full h-full p-5 overflow-y-auto'>
            <div className='flex justify-between items-center'>
                <h1 className="text-primary-1 font-bold">View Booking</h1>
                <div className='flex flex-row gap-4'>
                    <Link
                        to={"/bookings"}
                        className="bg-shade-1 rounded-md text-black hover:text-white hover:bg-shade-2 border border-black no-underline px-3 py-2"
                    >bookings</Link>
                    {
                        BookingPermissions.isAuthorized("edit", role) &&
                        <Link
                            to={`/bookings/edit/${id}`}
                            className="bg-primary-1 rounded-md text-white px-3 py-2 no-underline hover:text-white"
                        >edit</Link>
                    }
                </div>
            </div>

            <div className='border border-gray-400 mt-1 mb-8' />

            <h1 className='text-primary-2 text-lg font-bold'>View Booking</h1>
            <div className='flex flex-row w-full'>
                <div className='grid grid-cols-2 w-full'>
                    <div className='col-span-1 flex flex-row'>
                        <label className='text-primary-2 font-bold mr-2'>Booking number:</label>
                        <div className='text-gray-500 font-bold'>{booking?._id}</div>
                    </div>
                    <div className='col-span-1 flex flex-row'>
                        <label className='text-primary-2 font-bold mr-2'>User:</label>
                        <div className='text-gray-500 font-bold'>{`${booking?.user?.firstName} ${booking?.user?.lastName}`}</div>
                    </div>
                    <div className='col-span-1 flex flex-row'>
                        <label className='text-primary-2 font-bold mr-2'>Company:</label>
                        <div className='text-gray-500 font-bold'>{booking?.company?.name}</div>
                    </div>
                    <div className='col-span-1 flex flex-row'>
                        <label className='text-primary-2 font-bold mr-2'>Status:</label>
                        <div className='text-gray-500 font-bold'>{bookingStatusDisplay()}</div>
                    </div>
                    <div className='col-span-1 flex flex-row'>
                        <label className='text-primary-2 font-bold mr-2'>Cargo Type:</label>
                        <div className='text-gray-500 font-bold'>{booking?.cargoType}</div>
                    </div>

                    <div className='col-span-1 flex flex-row'>
                        <label className='text-primary-2 font-bold mr-2'>Fare:</label>
                        <div className='text-gray-500 font-bold'>{booking?.fare}</div>
                    </div>
                    <div className='col-span-1 flex flex-row'>
                        <label className='text-primary-2 font-bold mr-2'>Distance:</label>
                        <div className='text-gray-500 font-bold'>{booking?.distance}</div>
                    </div>
                    <div className='col-span-1 flex flex-row'>
                        <label className='text-primary-2 font-bold mr-2'>Timeslot :</label>
                        <div className='text-gray-500 font-bold'>{booking?.timeslot}</div>
                    </div>

                    {
                        trips.map((trip) => renderDriver(trip))
                    }
                </div>
                {
                    <div className='flex flex-col w-full h-fit gap-1'>
                        {renderChatButtons()}
                        {renderSupportButtons()}
                    </div>
                }
            </div>

            <div className="">

                <div className=" mt-8 sm:-mx-0">
                    <table className="min-w-full divide-y divide-gray-300">
                        <thead>
                            <tr>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                    address
                                </th>
                                <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">
                                    status
                                </th>
                                <th
                                    scope="col"
                                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                                >
                                    Country
                                </th>
                                <th
                                    scope="col"
                                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                                >
                                    Type
                                </th>
                                <th
                                    scope="col"
                                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                                >
                                    Products
                                </th>
                                <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                    Code
                                </th>

                            </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 ">
                            { 
                                Object.entries(booking?.transitPath || {})?.map(([_, points], index) => {
                                    return points.map((point, index) => <BookingPoint point = {point} index={index}/>)
                                }) 
                            }
                        </tbody>
                    </table>
                </div>
            </div>
            {
                trips.find( trip => trip?.trailer?._id && trip.status === "onroute") &&
                <GoogleMap
                    mapContainerStyle={{
                        width: 100 + "%",
                        height: 100 + "%",
                        borderRadius: 10,
                        borderColor: "GrayText",
                        borderWidth: 2,
                        marginTop: 4
                    }}
                    zoom={10}
                    onLoad={(map) => {
                        mapRef.current = map;
                    }}
                    options={{
                        styles: MapStyles,
                    }}
                >
                    {/* {
                        directions &&
                        <div className="absolute left-3 top-16 bg-white p-4 text-xl text-red-500 font-bold border-gray-300 border-2">
                            {`${(directions.routes[0].legs.reduce((value, leg) => value + leg.distance.value, 0) / 1000).toFixed(2)} km`}
                        </div>
                    } */}
                    {
                        // directions &&
                        // <DirectionsRenderer
                        //     options={{
                        //         markerOptions: {
                        //             icon: {
                        //                 path: 'M17.0710678,2.92893219 C20.9763107,6.83417511 20.9763107,13.1658249 17.0710678,17.0710678 L10,24.1421356 L2.92893219,17.0710678 C-0.976310729,13.1658249 -0.976310729,6.83417511 2.92893219,2.92893219 C6.83417511,-0.976310729 13.1658249,-0.976310729 17.0710678,2.92893219 Z M10,5 C7.23857625,5 5,7.23857625 5,10 C5,12.7614237 7.23857625,15 10,15 C12.7614237,15 15,12.7614237 15,10 C15,7.23857625 12.7614237,5 10,5 Z',
                        //                 fillColor: 'rgb(0,0,255)',
                        //                 fillOpacity: 1,
                        //                 scale: 1.2,
                        //                 strokeColor: 'rgba(255, 255, 255, 0.6)',
                        //                 strokeWeight: 15,
                        //             },
                        //             anchorPoint: {
                        //                 x: 0.5,
                        //                 y: 0.5
                        //             }
                        //         }
                        //     }}
                        //     directions={directions}
                        // />
                    }
                    {
                        nextPointDirections?.length > 0 &&
                        nextPointDirections.map((directions, index) => ( 
                            <DirectionsRenderer
                                options={{
                                    suppressMarkers: true
                                }}
                                directions={directions}
                            />
                        ))
                    }
                    {
                        <MarkerClusterer
                            averageCenter={true}
                            gridSize={2} 
                            maxZoom={18}
                        >
                            {
                                (clusterer) => {
                                    return (
                                        <>
                                            {
                                                booking?.path &&
                                                booking?.path.map((point) => {
                                                    return(
                                                        <Marker
                                                            icon={{
                                                                path: 'M17.0710678,2.92893219 C20.9763107,6.83417511 20.9763107,13.1658249 17.0710678,17.0710678 L10,24.1421356 L2.92893219,17.0710678 C-0.976310729,13.1658249 -0.976310729,6.83417511 2.92893219,2.92893219 C6.83417511,-0.976310729 13.1658249,-0.976310729 17.0710678,2.92893219 Z M10,5 C7.23857625,5 5,7.23857625 5,10 C5,12.7614237 7.23857625,15 10,15 C12.7614237,15 15,12.7614237 15,10 C15,7.23857625 12.7614237,5 10,5 Z',
                                                                fillColor: 'rgb(0,0,255)',
                                                                fillOpacity: 1,
                                                                scale: 1.2,
                                                                strokeColor: 'rgba(255, 255, 255, 0.6)',
                                                                strokeWeight: 15,
                                                            }}
                                                            clusterer={clusterer}
                                                            position={{
                                                                lng: point?.location?.coordinates[0],
                                                                lat: point?.location?.coordinates[1]
                                                            }}
                                                            anchorPoint = {{
                                                                x: 0.5,
                                                                y: 0.5
                                                            }}
                                                        />
                                                    )
                                                })
                                            }
                                            {
                                                (booking?.trailerLocationCoordinates.length > 0) &&
                                                booking?.trailerLocationCoordinates.map((trailerLocation) => (
                                                    <Marker
                                                        icon={vehicleIcon}
                                                        clusterer={clusterer}
                                                        position={{
                                                            lng: trailerLocation.coordinates[0],
                                                            lat: trailerLocation.coordinates[1]
                                                        }}
                                                    />
                                                ))
                                            }
                                        </>
                                    );
                                }
                            }
                        </MarkerClusterer>
                    }
                    {routeArcs.map((arc, index) => (
                        <Polyline
                            key={`arc-${index}`}
                            path={arc.path}
                            options={arc.options}
                        />
                    ))}
                </GoogleMap>
            }
        </div>
    )

}

export default ViewBooking;
