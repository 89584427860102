import {connect} from 'react-redux'
import ExchangeRates from '../components/ExchangeRates';
import { showLoaderAction, showErrorAction } from '../../common/modules/modal';

const mapStateToProps = (state) =>({
    role:  state.profile.role
});

const mapActionCreators = {
    showLoaderAction,
    showErrorAction
};

export default connect (mapStateToProps,mapActionCreators)(ExchangeRates); 