import * as NavigationPermissions from "../modules/NavigationPermissions";

import {
  Bars3Icon,
  CalendarIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
// import { Fragment } from 'react'
import { Dialog, Transition } from "@headlessui/react";
import { Link, Route, Routes, useNavigate } from "react-router-dom";
import React, { Fragment, useEffect, useState } from "react";

import AssignTripContainer from "../../trips/container/AssignTripContainer";
import BillingsContainer from "../../billings/container/BillingsContainer";
import BookingsContainer from "../../bookings/container/BookingsContainer";
import CarsContainer from "../../cars/container/CarsContainer";
import CompaniesContainer from "../../companies/container/CompaniesContainer";
import DashBoardContainer from "../../dashboard/container/DashBoardContainer";
import EditBookingContainer from "../../bookings/container/EditBookingContainer";
import EditCarContainer from "../../cars/container/EditCarContainer";
import EditCompanyContainer from "../../companies/container/EditCompanyContainer";
import EditFleetContainer from "../../fleets/container/EditFleetContainer";
import EditTrailerContainer from "../../trailers/container/EditTrailerContainer";
import EditUserContainer from "../../users/container/EditUserContainer";
import EditVehicleContainer from "../../vehicles/container/EditVehicleContainer";
import FleetsContainer from "../../fleets/container/FleetsContainer";
import GroupingCenter from "../../groupingCenter/GroupingCenter";
// import HomeIcon from '@mui/icons-material/Home';
import MenuIcon from "@mui/icons-material/Menu";
import NewBillingContainer from "../../billings/container/NewBillingContainer";
import NewBookingContainer from "../../bookings/container/NewBookingContainer";
import NewCarContainer from "../../cars/container/NewCarContainer";
import NewCompanyContainer from "../../companies/container/NewCompanyContainer";
import NewFleetContainer from "../../fleets/container/NewFleetContainer";
import NewGuestBooking from '../../guest/NewGuestBooking';
import NewTrailerContainer from "../../trailers/container/NewTrailerContainer";
import NewUserContainer from "../../users/container/NewUserContainer";
import NewVehicleContainer from "../../vehicles/container/NewVehicleContainer";
import ProfileContainer from "../../profile/container/ProfileContainer";
import Settings from "../../settings/Settings";
import TrailersContainer from "../../trailers/container/TrailersContainer";
import TripsContainer from "../../trips/container/TripsContainer";
import UsersContainer from "../../users/container/UsersContainer";
import VehiclesContainer from "../../vehicles/container/VehiclesContainer";
import ViewBookingContainer from "../../bookings/container/ViewBookingContainer";
import ViewCarContainer from "../../cars/container/ViewCarContainer";
import ViewCompanyContainer from "../../companies/container/ViewCompanyContainer";
import ViewFleetContainer from "../../fleets/container/ViewFleetContainer";
import ViewTrailerContainer from "../../trailers/container/ViewTrailerContainer";
import ViewTripContainer from "../../trips/container/ViewTripContainer";
import ViewUserContainer from "../../users/container/ViewUserContainer";
import ViewVehicleContainer from "../../vehicles/container/ViewVehicleContainer";
import PaymentsContainer from "../../payments/container/PaymentsContainer";
import ViewPaymentContainer from "../../payments/container/ViewPaymentContainer";
import NewPaymentContainer from '../../payments/container/NewPaymentContainer';
import PaymentMethodsContainer from "../../payments/container/PaymentMethodsContainer";
import TrackingContainer from "../../tracking/container/TrackingContainer";

import SupportsContainer from "../../supports/container/SupportsContainer";
import NewSupportContainer from "../../supports/container/NewSupportContainer";
import EditSupportContainer from "../../supports/container/EditSupportContainer";
import ViewSupportContainer from "../../supports/container/ViewSupportContainer";

import StationsContainer from "../../stations/container/StationsContainer";
import NewStationContainer from "../../stations/container/NewStationContainer";
import EditStationContainer from "../../stations/container/EditStationContainer";
import ViewStationContainer from "../../stations/container/ViewStationContainer";

import EnquiriesContainer from "../../enquiries/container/EnquiriesContainer";
import ViewEnquiryContainer from "../../enquiries/container/ViewEnquiryContainer";
import EditEnquiryContainer from "../../enquiries/container/EditEnquiryContainer";

import ChatsContainer from "../../chats/container/ChatsContainer";
import ActivitiesContainer from "../../activities/container/ActivitiesContainer";

import WalletsContainer from "../../wallets/container/WalletsContainer";
import ViewWalletContainer from "../../wallets/container/ViewWalletContainer";
import NewWalletContainer from "../../wallets/container/NewWalletContainer";

import ExchangeRatesContainer from "../../exchangeRates/container/ExchangeRatesContainer";
import NewExchangeRateContainer from "../../exchangeRates/container/NewExchangeRateContainer";

import { images } from "../../../assets";
import { createSocket, disconnectSocket } from '../../../sockets/SocketManager';

const navigation = [
  { name: "Dashboard", href: "/", icon: HomeIcon, current: true },
  { name: "Enquiries", href: "/enquiries", icon: HomeIcon, current: true },
  { name: "Tracking", href: "/tracking", icon: UsersIcon, current: false },
  { name: "Support Tickets", href: "/supports", icon: UsersIcon, current: false },
  { name: "Companies", href: "/companies", icon: UsersIcon, current: false },
  { name: "Fleets", href: "/fleets", icon: FolderIcon, current: false },
  { name: "Billing", href: "/billings", icon: DocumentDuplicateIcon, current: false },
  { name: "Cars", href: "/cars", icon: CalendarIcon, current: false },
  { name: "Trips", href: "/trips", icon: UsersIcon, current: false },
  { name: "Stations", href: "/stations", icon: UsersIcon, current: false },
  {
    name: "Trailers",
    href: "/trailers",
    icon: DocumentDuplicateIcon,
    current: false,
  },
  {
    name: "Payments",
    href: "/payments",
    icon: DocumentDuplicateIcon,
    current: false,
  },
  {
    name: "Grouping",
    href: "/groupingCenter",
    icon: DocumentDuplicateIcon,
    current: false,
  },
  {
    name: "Wallets",
    href: "/wallets",
    icon: DocumentDuplicateIcon,
    current: false,
  },
  {
    name: "Exchange Rates",
    href: "/exchangeRates",
    icon: DocumentDuplicateIcon,
    current: false,
  },
];
const settings = [
  { id: 1, name: "Users", href: "/users", initial: "U", current: false },
  { id: 2, name: "Bookings", href: "/bookings", initial: "B", current: false },
  { id: 3, name: "Vehicles", href: "/vehicles", initial: "V", current: false },
  { id: 4, name: "Activities", href: "/activities", initial: "A", current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const NavBar = ({ logoutAction, firstName, role }) => {
  const [mobile, setMobile] = useState(false);
  const [showNavBar, setShowNavBar] = useState(true);
  const [selected, setSelected] = useState("Dashboard");
  const [sidebarOpen, setSidebarOpen] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    createSocket();
    return () => {
      disconnectSocket();
    };
  }, []);

  useEffect(() => {
    const windowResized = () => {
      if (window.innerWidth < 1064) {
        setMobile(true);
        setShowNavBar(false);
      } else {
        setMobile(false);
        setShowNavBar(true);
      }
    };

    windowResized();

    window.addEventListener("resize", windowResized);

    return () => window.removeEventListener("resize", windowResized);
  }, []);

  return (
    <div className="flex flex-col w-full h-full">
      {mobile && (
        <div className="flex flex-row w-full items-center p-2 h-[50px] shadow">
          <button
            className="py-1 px-2 bg-primary-1 text-white rounded-lg"
            onClick={() => setShowNavBar(!showNavBar)}
          >
            <MenuIcon fontSize="medium" />
          </button>
          <div className="font-bold text-white ml-5 text-xl">{selected}</div>
        </div>
      )}
      <div
        className={
          mobile
            ? "flex flex-row w-full h-[calc(100%_-_50px)]"
            : "flex flex-row w-full h-full"
        }
      >
        {/* {
                    showNavBar &&
                    <div className={ mobile ? "flex flex-col p-3 border-r-2 border-gray-500 w-60 h-[calc(100%_-_50px)] bg-primary-1 fixed z-50 justify-between" : "flex flex-col p-3 border-r-2 border-gray-500 h-full w-40 bg-primary-1 overflow-y-auto justify-between" }>
                        <div>
                            {
                                topSideBarItems.map((item, index)=>(
                                    NavigationPermissions.isAuthorized(item.name, role) &&
                                    <Link
                                        key={index}
                                        to={item.path}
                                        onClick={()=>{
                                            if(mobile) setShowNavBar(false);
                                           setSelected(item.title)
                                        }}
                                    >
                                        <div className={`flex flex-row w-full py-1 px-2 border-b-2 border-gray-500 rounded-lg mb-1 items-center ${ selected == item.title ? "border-r-4 border-white bg-white" : "border-r-0 border-primary-1 bg-gray-300"}`}>
                                            <div className={`${ (selected == item.title) ? "text-primary-1" : "text-gray-800" }`}>{item.icon}</div>
                                            <div className={`text-sm ${(selected == item.title) ? "font-bold text-primary-1" : "font-normal text-gray-800" }  `}>{item.title}</div>
                                        </div>
                                    </Link>
                                ))
                            }
                        </div>
                        <div>
                            {
                                bottomSideBarItems.map((item, index)=>(
                                    <Link
                                        key={index}
                                        to={item.path}
                                        onClick={()=>setSelected("settings")}
                                    >
                                        <div className={`flex flex-row w-full py-1 px-2 border-b-2  rounded-lg mb-1 items-center ${ selected == "settings" ? "bg-white text-primary-1 font-bold border-white" : "text-gray-800 bg-gray-300 border-gray-300" }`} >
                                            <div className="">{item.icon}</div>
                                            <div className="text-sm">{item.title}</div>
                                        </div>
                                    </Link>
                                ))
                            }
                            <div className={`flex flex-row w-full py-3 px-2 border-b-2 border-gray-500 rounded-lg items-center ${ selected == "profile" ? "border-4 border-white bg-white" : "border-0 border-primary-1 bg-gray-300"}`}>
                                <div className="flex flex-col">
                                    <Link
                                        onClick={ () => setSelected("profile")}
                                        to={"/profile"}
                                    >
                                        <div className = {`flex flex-row items-center justify-center ${ (selected == "profile") ? "text-primary-1" : "text-gray-800 bg-gray-300"}`}>
                                            <AccountCircleIcon fontSize="small"/>
                                            <div className={`text-xs ml-2 ${ (selected == "profile") ? "font-bold text-primary-1" : " font-normal text-gray-800"}`}>{firstName}</div>
                                        </div>

                                    </Link>
                                    <button
                                        className="text-sm underline"
                                        onClick={()=>{
                                            logoutAction();
                                            navigate("/");
                                        }}
                                    >Sign out</button>
                                </div>
                            </div>
                        </div>
                    </div>
                } */}

        <>
          <div>
            <Transition.Root show={sidebarOpen} as={Fragment}>
              <Dialog
                as="div"
                className="relative z-50 lg:hidden"
                onClose={setSidebarOpen}
              >
                <Transition.Child
                  as={Fragment}
                  enter="transition-opacity ease-linear duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity ease-linear duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="fixed inset-0 bg-gray-900/80" />
                </Transition.Child>

                <div className="fixed inset-0 flex">
                  <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="-translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="-translate-x-full"
                  >
                    <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                      <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
                        <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                          <button
                            type="button"
                            className="-m-2.5 p-2.5"
                            onClick={() => setSidebarOpen(false)}
                          >
                            <span className="sr-only">Close sidebar</span>
                            <XMarkIcon
                              className="h-6 w-6 text-white"
                              aria-hidden="true"
                            />
                          </button>
                        </div>
                      </Transition.Child>
                      {/* Sidebar component, swap this element with another sidebar if you like */}
                      <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-2">
                        <div className="flex h-16 shrink-0 items-center">
                        <img className="h-8" src={images.Logo} alt="wheelsitno" />
                        </div>
                        <nav className="flex flex-1 flex-col">
                          <ul className="flex flex-1 flex-col gap-y-7">
                            <li>
                              <ul className="-mx-2 space-y-1">
                                {navigation.map(
                                  (item) =>
                                    NavigationPermissions.isAuthorized(
                                      item.name,
                                      role
                                    ) && (
                                      <li key={item.name}>
                                        <button
                                          onClick={() => navigate(item.href)}
                                          className={classNames(
                                            item.current
                                              ? "bg-gray-50 text-primary-2 hover:no-underline"
                                              : "text-primary-1 hover:text-primary-2 hover:no-underline hover:bg-gray-50",
                                            "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold no-underline hover:no-underline"
                                          )}
                                        >
                                          <item.icon
                                            className={classNames(
                                              item.current
                                                ? "text-primary-2 no-underline"
                                                : "text-gray-400 group-hover:text-primary-2 no-underline",
                                              "h-6 w-6 shrink-0"
                                            )}
                                            aria-hidden="true"
                                          />
                                          {item.name}
                                        </button>
                                      </li>
                                    )
                                )}
                              </ul>
                            </li>
                            <li>
                              <div className="text-xs font-semibold leading-6 text-gray-400">
                                Your settings
                              </div>
                              <ul className="-mx-2 mt-2 space-y-1">
                                {settings.map((team) => (
                                  <li key={team.name}>
                                    <button
                                      onClick={() => navigate(team.href)}
                                      className={classNames(
                                        team.current
                                          ? "bg-gray-50 text-primary-2 hover:no-underline"
                                          : "text-primary-1 hover:text-primary-2 hover:bg-gray-50 hover:no-underline",
                                        "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold "
                                      )}
                                    >
                                      <span
                                        className={classNames(
                                          team.current
                                            ? "text-primary-2 border-primary-1 hover:no-underline"
                                            : "text-gray-400 border-gray-200 group-hover:border-primary-1 group-hover:text-primary-2 hover:no-underline",
                                          "flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white"
                                        )}
                                      >
                                        {team.initial}
                                      </span>
                                      <span className="truncate">
                                        {team.name}
                                      </span>
                                    </button>
                                  </li>
                                ))}
                              </ul>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
              {/* Sidebar component, swap this element with another sidebar if you like */}
              <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6">
                <div className="flex h-12  justify-center gap-4 shrink-0 items-center">
                <img className="h-12 py-2" src='/long_logo.png' alt="wheelsitno" />
                <h1 className="text-2xl font-semibold leading-none text-primary-1 lg:text-xl pl-1">Wheelsitnow</h1>
                </div>
                <nav className="flex flex-1 flex-col">
                  <ul className="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul className="-mx-2 space-y-1">
                        {navigation.map((item) => (
                          <li key={item.name}>
                            <button
                              onClick={()=> navigate(item.href)}
                              className={classNames(
                                item.current
                                  ? "bg-gray-50 text-primary-2 hover:no-underline"
                                  : "text-primary-1 hover:text-black hover:bg-gray-50 hover:no-underline",
                                "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                              )}
                            >
                              <item.icon
                                className={classNames(
                                  item.current
                                    ? "text-primary-2"
                                    : "text-gray-400 group-hover:text-primary-2",
                                  "h-6 w-6 shrink-0"
                                )}
                                aria-hidden="true"
                              />
                              {item.name}
                            </button>
                          </li>
                        ))}
                      </ul>
                    </li>
                    <li>
                      <div className="text-xs font-semibold leading-6 text-gray-400">
                        Your settings
                      </div>
                      <ul className="-mx-2 mt-2 space-y-1">
                        {settings.map((team) => (
                          <li key={team.name}>
                            <button
                              onClick={ () => navigate(team.href) }
                              className={classNames(
                                team.current
                                  ? "bg-gray-50 text-primary-2 hover:no-underline"
                                  : "text-primary-1 hover:text-primary-2 hover:bg-gray-50 hover:no-underline",
                                "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                              )}
                            >
                              <span
                                className={classNames(
                                  team.current
                                    ? "text-primary-2 border-primary-1"
                                    : "text-gray-400 border-gray-200 group-hover:border-primary-1 group-hover:text-primary-2",
                                  "flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white"
                                )}
                              >
                                {team.initial}
                              </span>
                              <span className="truncate">{team.name}</span>
                            </button>
                          </li>
                        ))}
                      </ul>
                    </li>
                    <li className="-mx-6 ">
                      <ul>
                      <li>
                      <Link
                        onClick={ () => setSelected("profile")}
                        to={"/profile"}
                        className="flex items-center gap-x-4 px-6 py-3 text-sm font-semibold leading-6 text-primary-2 hover:bg-gray-50 hover:no-underline hover:text-primary-1"
                      >
                        <img
                          className="h-8 w-8 rounded-full bg-gray-50"
                          src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                          alt=""
                        />

                        <div>
                        <p className="p-0">{firstName}</p>
                       <p className="text-xs text-neutral-7 p-0 hover:no-underline -mt-2" >{role}</p>
                        </div>
                      </Link>
                      </li>
                      <li className="my-1 flex w-full items-center gap-4 gap-x-4 px-6 hover:cursor-pointer">
                      <button
                        onClick={()=>{
                          logoutAction();
                          navigate("/");
                      }}
                        className="text-primary-1 w-full hover:text-black hover:bg-gray-50 hover:no-underline group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                      >
                        {" "}
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
                          />
                        </svg>
                        <span>Logout</span>
                      </button>


                    </li>
                      </ul>

                    </li>

                  </ul>
                </nav>
              </div>
            </div>

            <div className="sticky top-0 z-40 flex items-center gap-x-6 bg-white px-4 py-4 shadow-sm sm:px-6 lg:hidden">
              <button
                type="button"
                className="-m-2.5 p-2.5 text-primary-1 lg:hidden"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <Bars3Icon className="h-6 w-6" aria-hidden="true" />
              </button>
              <div className="flex-1 text-sm font-semibold leading-6 text-primary-2">
                Dashboard
              </div>
              <button 
                // href="#addLink"
                onClick={()=> {}}
              >
                <span className="sr-only">Your profile</span>
                <img
                  className="h-8 w-8 rounded-full bg-gray-50"
                  src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                  alt=""
                />
              </button>
            </div>

            <div className="py-10 lg:pl-72"></div>
          </div>
        </>

        <div className={mobile ? "flex w-full" : "flex w-full"}>
          <Routes>
            <Route path="/" element={<DashBoardContainer />} />

            <Route path="/companies" element={<CompaniesContainer />} />
            <Route path="/companies/new" element={<NewCompanyContainer />} />
            <Route
              path="/companies/view/:id"
              element={<ViewCompanyContainer />}
            />
            <Route
              path="/companies/edit/:id"
              element={<EditCompanyContainer />}
            />

            <Route path="/fleets" element={<FleetsContainer />} />
            <Route path="/fleets/new" element={<NewFleetContainer />} />
            <Route path="/fleets/view/:id" element={<ViewFleetContainer />} />
            <Route path="/fleets/edit/:id" element={<EditFleetContainer />} />

            <Route path="/users" element={<UsersContainer />} />
            <Route path="/users/new" element={<NewUserContainer />} />
            <Route path="/users/view/:id" element={<ViewUserContainer />} />
            <Route path="/users/edit/:id" element={<EditUserContainer />} />

            <Route path="/bookings" element={<BookingsContainer />} />
            <Route path="/bookings/new" element={<NewBookingContainer />} />
            <Route
                path = "/newGuestBooking"
                element = {<NewGuestBooking/>}
              />
            <Route
              path="/bookings/view/:id"
              element={<ViewBookingContainer />}
            />
            <Route
              path="/bookings/edit/:id"
              element={<EditBookingContainer />}
            />

            <Route path="/cars" element={<CarsContainer />} />
            <Route path="/cars/new" element={<NewCarContainer />} />
            <Route path="/cars/view/:id" element={<ViewCarContainer />} />
            <Route path="/cars/edit/:id" element={<EditCarContainer />} />

            <Route path="/vehicles" element={<VehiclesContainer />} />
            <Route path="/vehicles/new" element={<NewVehicleContainer />} />
            <Route
              path="/vehicles/view/:id"
              element={<ViewVehicleContainer />}
            />
            <Route
              path="/vehicles/edit/:id"
              element={<EditVehicleContainer />}
            />

            <Route path="/trailers" element={<TrailersContainer />} />
            <Route path="/trailers/new" element={<NewTrailerContainer />} />
            <Route
              path="/trailers/view/:id"
              element={<ViewTrailerContainer />}
            />
            <Route
              path="/trailers/edit/:id"
              element={<EditTrailerContainer />}
            />

            <Route path="/groupingCenter" element={<GroupingCenter />} />

            <Route path="/trips" element={<TripsContainer />} />
            <Route path="/trips/view/:id" element={<ViewTripContainer />} />
            <Route path="/trips/assign/:id" element={<AssignTripContainer />} />

            <Route path="/billings/" element={<BillingsContainer />} />
            <Route path="/billings/new" element={<NewBillingContainer />} />

            <Route path="/settings" element={<Settings />} />
            <Route path="/profile" element={<ProfileContainer />} />

            <Route path="/payments" element={<PaymentsContainer />} />
            <Route path="/payments/new/:id" element={<NewPaymentContainer />} />
            <Route path="/payments/view/:id" element={<ViewPaymentContainer />} />
            <Route path="/payments/paymentMethods" element={<PaymentMethodsContainer />} />

            <Route path="/tracking" element={<TrackingContainer />} />

            <Route path="/supports" element={<SupportsContainer />} />
            <Route path="/supports/new" element={<NewSupportContainer />} />
            <Route path="/supports/view/:id" element={<ViewSupportContainer />} />
            <Route path="/supports/edit/:id" element={<EditSupportContainer />} />

            <Route path="/chats/:id" element={<ChatsContainer />} />

            <Route path="/stations" element={<StationsContainer />} />
            <Route path="/stations/new" element={<NewStationContainer />} />
            <Route path="/stations/view/:id" element={<ViewStationContainer />} />
            <Route path="/stations/edit/:id" element={<EditStationContainer />} />

            <Route path="/enquiries" element={<EnquiriesContainer />} />
            <Route path="/enquiries/view/:id" element={<ViewEnquiryContainer />} />
            <Route path="/enquiries/edit/:id" element={<EditEnquiryContainer />} />

            <Route path="/activities" element={<ActivitiesContainer />} />

            <Route path="/wallets" element={<WalletsContainer />} />
            <Route path="/wallets/view/:id" element={<ViewWalletContainer />} />
            <Route path="/wallets/new" element={<NewWalletContainer />} />

            <Route path="/exchangeRates" element={<ExchangeRatesContainer />} />
            <Route path="/exchangeRates/new" element={<NewExchangeRateContainer />} />
            
          </Routes>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
