import ReduxStorage  from '../../store/store';
import { getSocket} from "../SocketManager";

export const register = async (callback) => {
    try{
        const socket = await getSocket();
        const profile = ReduxStorage.store.getState()?.profile;
        socket.on(`BookingNew_${profile.id}`, (data) => {
            callback();
        })
    }
    catch(error){
        console.log("Error new booking emitter registration", error);
    }
}

export const unregister = async () => { 
    try {
        const socket = await getSocket();
        const profile = ReduxStorage.store.getState()?.profile;
        socket.off(`BookingNew_${profile.id}`);
    } catch (error) {
        console.log("Error unregister new booking emitter", error);
    }
}